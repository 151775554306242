body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  height: 100vh;
}

.button-wrapper {
  height: 0px;
  width: 0px;
}

.button-style:hover {
  background: #c0c1c2;
}

.button-style {
  outline: none;
  position: absolute;
  z-index: 1;
  font-family: 'Raleway';
  font-size: 24px;
  height: 50px;
  width: 150px;
  border-radius: 50px;
  background: #ffffff;
  color: #3664c7;
  border: 0px;
  transform: translate(33px, 100px);
}

.button-map:hover {
  background: #3664c7;
}

.button-map {
  outline: none;
  position: absolute;
  z-index: 1;
  font-family: 'Raleway';
  font-size: 24px;
  height: 50px;
  width: 150px;
  border-radius: 50px;
  background: #20489e;
  color: #ffffff;
  border: 0px;
  transform: translate(33px, 33px);
}

.mapboxgl-popup-content {
  text-align: left;
  background-color: rgb(20,20,20,0.75);
  font: 400 15px/22px 'Raleway', 'Source Sans Pro', 'Helvetica Neue', Sans-serif;
  padding: 0;
  width: 180px;
}

.mapboxgl-popup-content-wrapper {
  padding: 1%;
}

.mapboxgl-popup-content h3 {
  background-color: rgb(20,20,20,0.75);
  color: #57bdff;
  margin: 0;
  display: block;
  padding: 10px;
  /* border-radius: 10px 10px 0 0; */
  font-weight: 700;
  /* margin-top: -15px; */
}

.mapboxgl-popup-content h4 {
  background-color: rgb(20,20,20,0.75);
  /* border-radius: 0 0 10px 10px; */
  color: #fff;
  margin: 0;
  display: block;
  padding: 10px;
  font-weight: 400;
}

.mapboxgl-popup-content div {
  padding: 10px;
}

.mapboxgl-container .leaflet-marker-icon {
  cursor: pointer;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
  margin-top: 15px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: transparent;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    border-bottom-color: transparent;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    border-top-color: transparent;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: transparent;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: transparent;
}

.Navigation-Button-Container:hover {
  background: #c0c1c2;
}

#location-name {
  font-size: 20px;
}

#location-address {
  font-size: 16px;
}